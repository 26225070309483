body
  font-family: "Poppins", sans-serif

ul
  padding-left: 0
  list-style: none
  margin-bottom: 0

a
  outline: none !important
  color: inherit
  &:hover
    color: inherit
    text-decoration: none

button
  outline: none !important

textarea:focus, input:focus
  outline: none !important

// Utils
.btn-danger, .badge-danger
  color: white !important

.btn-dashed
  border-style: dashed
  border-color: #c4cdd6
  border-width: 1px
  transition: .25 ease-in-out
  color: #666
  text-align: center

  &:hover
    color: var(--primary)
    border-color: var(--primary)
    border-style: solid
    background: lighten($primary, 30%)

a:hover > .card:not(.--outlined)
  box-shadow: 0px 0px 3px 3px rgba(0,0,0,0.06)

.flex-middle
  display: flex
  flex-direction: row
  align-items: center

.flex-middle-center
  display: flex
  flex-direction: row
  align-items: center
  justify-content: center

.flex-nowrap
  flex-wrap: nowrap
  &.row
    margin-right: 0
    margin-left: 0
    &>*
      &:first-child
        padding-left: 0
      &:last-child
        padding-right: 0

// Components

.card
  border-radius: 8px
  transition: .25s ease-in-out
  .card-body
    height: 100%
  &.--outlined
    box-shadow: none
    border: 1px solid #ccc
    background: none

.icon-btn
  @extend %base-border
  background: none
  border: none
  outline: none
  transition: .25s ease-in-out
  display: inline-flex
  justify-content: center
  align-items: center
  cursor: pointer

  @each $size in $commonSizes
    &.icon-btn.icon-btn--#{$size}
      @if $size >= 30
        font-size: 1.1rem
      @else
        font-size: 1rem
      @include size(#{$size+"px"})

.white-btn
  border: 1px solid #CCCCCC
  background: white
  color: #666
  transition: .25s ease-in-out
  &:hover
    opacity: 1
    box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.1)

.btn-disabled
  opacity: .4
  pointer-events: none
  cursor: default

// **********************************
// Component styles *****************
// **********************************

.document-body--sidenav-on
  background: #f8f8f8
  min-height: 100vh
  overflow: auto
  display: flex
  flex-direction: column
  transition: .25s ease-in-out

  @include media-breakpoint-down(sm)
    margin-left: 0

.sidenav-main--nav--expanded + .document-body--sidenav-on
  margin-left: 230px

.default-progress-bar
  width: 100%
  display: flex
  flex-direction: column-reverse
  .progress-bar--number
    display: inline-block
    position: relative
    color: #999
    font-size: .8rem
    font-weight: 600
    margin-bottom: 5px
  &.default-progress-bar-active
    flex-direction: row
    align-items: center
    .progress-bar--number
      margin-left: 1rem
      margin-bottom: 0
  .fw-bar
    width: 100%
    height: 3px
    background: #ddd

    .percentage-bar
      height: 3px
      background-size: 1rem 1rem

.priority-details
  .priority-details-title
    font-size: .9rem
    color: #666
  .priority-details--item
    height: 1rem
    background: #ccc
    width: 5px
    margin-right: .4rem
    display: inline-block
    border-radius: 10px
  .priority-details--item-active
    background: #FF5858

.page-title--icon
  height: 40px
  width: 40px
  font-size: 1.2rem
  background: var(--primary)
  color: white
  display: flex
  justify-content: center
  align-items: center
  box-shadow: 0px 1px 3px 1px rgba(0,0,0,0.07)
  margin-right: 1rem

  @extend %base-border

.page-title
  display: flex
  align-items: center

  .page-title--text
    font-size: 1.2rem
    font-weight: 600
    color: #333
  .page-title--subtext
    font-size: .9rem
    color: #666
    font-weight: 600

.slack-icon
  color: #e01e5a

.label-required::before
  display: inline-block
  margin-right: 4px
  color: #f5222d
  font-size: 14px
  line-height: 1
  content: '*'

.form-group
  margin-top: 1rem
  margin-bottom: 1rem

  label
    color: var(--primary)
    font-weight: 600
    display: block
    font-size: 13px

  .form-control[type="file"]
    display: none 


// *************************************************

.btn-no-style
  @extend %btn-no-style
.secondary 
  background-color: #a6b3f7
  border-radius: 9px
  border: 1px solid transparent
  color: var(--darkviolet)


// *************************************************
  
.btn-xs
  @include button-size(4px, 8px, 12px, 3px)

.icon-dual
  color: #6c757d
  fill: rgba(108,117,125,.12)

.feather-icon
  width: 1em
  height: 1em

.menu-list
  display: flex
  align-items: center
  background: #edeeef
  color: #6c757d
  border-radius: var(--border-radius)
  border: var(--border)
  li:not(:last-child) a
    border-right: var(--border)

  & > *:first-child > a
    border-radius: var(--border-radius) 0 0 var(--border-radius)

  & > *:last-child > a
    border-radius: 0 var(--border-radius) var(--border-radius) 0

  & > * > a
    cursor: pointer
    text-transform: uppercase
    font-size: 0.8rem
    font-weight: 600
    color: #666
    padding: 0.5rem 1rem
    display: block
    transition: 0.25s ease-in-out
    &:hover
      background: var(--primary)
      color: white !important

    &.active
      background: var(--darkviolet)
      color: white

.abs-a-y
  top: 50%
  transform: translateY(-50%)