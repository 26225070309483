$primary: #4B61E9
$primary-t: rgba(106, 129, 241,.2)
$primary-gradient: linear-gradient(180deg, $primary 0%, #7DB7E9 100%)

$danger: #F84040
$warning: #FDC55A
$success: #13B157
$neutral: #5DB3FF
$hold: #FBA21D

$darkgray: #6C757D
$darkgray-t: rgba(108, 117, 125,.2)
$mediumgray: #4F4F4F
$darkestgray: #242939
$darkviolet-sec: #1D2644
$gray: #aaa
$lightgray: #E5E5E5
$darkviolet: #313C6D
$neutralblue: #E9ECFD
$secondary: $darkviolet
$h-pri: #F65814
$m-pri: #A231CA
$l-pri: #11AEA5
$gray-lines: #DDDEE9
$mediumblue: #A6B3F7

$theme-colors: ( "darkgray": $darkgray, "gray": $gray, "darkviolet": $darkviolet, "lightgray": $lightgray, "hold":$hold,"darkviolet-sec":$darkviolet-sec,"mediumgray":$mediumgray )
$colors: ( "h-pri": $h-pri, "m-pri": $m-pri, "l-pri": $l-pri, "neutralblue": $neutralblue,"primary-t": $primary-t, "gray-lines":$gray-lines, "darkgray-t":$darkgray-t,"primary-gradient":$primary-gradient,"mediumblue":$mediumblue,"darkviolet-sec":$darkviolet-sec,"mediumgray":$mediumgray )
$border-radius: 4px
$border-radius-lg: 4px
$border-radius-sm: 4px

$btn-font-size-sm: 0.8125rem

$btn-font-weight: 400

$badge-font-weight: 400
$badge-border-radius: 3px

$btn-padding-y-sm: 6px
$btn-padding-x-sm: 15px

$card-spacer-x: 15px
$card-spacer-y: 15px
$card-border-color: rgb(230, 231, 239)

$close-font-weight: 500

$btn-focus-width: 0
$btn-focus-box-shadow: none
$card-cap-bg: #f7f8ff

$input-border-radius: 5px
$input-focus-border-color: $primary
$input-btn-focus-box-shadow: 0 0 0 3px rgba(59, 91, 253, 0.1)

$font-weight-bold: 500
$font-weight-bolder: 500

\:root
  --border-radius: #{$border-radius}
  --border: 1px solid $gray-lines

@import "~bootstrap/scss/functions"
@import "~bootstrap/scss/variables"
@import "~bootstrap/scss/mixins"
@import "~bootstrap/scss/root"
@import "~bootstrap/scss/reboot"
@import "~bootstrap/scss/type"
@import "~bootstrap/scss/grid"
@import "~bootstrap/scss/tables"
@import "~bootstrap/scss/forms"
@import "~bootstrap/scss/buttons"
@import "~bootstrap/scss/card"
@import "~bootstrap/scss/pagination"
@import "~bootstrap/scss/progress"
@import "~bootstrap/scss/list-group"
@import "~bootstrap/scss/utilities"

$swal2-border-radius: $border-radius
$swal2-confirm-button-border-radius: $border-radius
$swal2-cancel-button-border-radius: $border-radius
$swal2-confirm-button-background-color: $primary
$swal2-cancel-button-background-color: $secondary
$swal2-title-font-size: 1.3rem
$swal2-warning: $danger

@import '~sweetalert2/src/sweetalert2'

@mixin button-size($padding-vertical, $padding-horizontal, $font-size, $border-radius)
  padding: $padding-vertical $padding-horizontal
  font-size: $font-size
  border-radius: $border-radius
  &.is-round
    padding: $padding-vertical $padding-horizontal
